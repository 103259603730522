<template>
  <div
    class="floating-menu"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    ref="menuContainer"
  >
    <b-button
      variant="colorBlue"
      class="floating-menu-item floating-menu-item1"
      v-b-tooltip.hover
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      title="Contact Us"
      @click="contactPage"
      ref="contactButton"
    >
      <img src="@/assets/images/icons/button2.svg" class="" alt="image" />
    </b-button>

    <transition name="slide">
      <div v-if="menuOpen" class="floating-menu-items" ref="menuItems">
        <!-- WhatsApp Icon -->
        <b-button
          variant="success"
          :href="whatsappLink"
          target="_blank"
          v-b-tooltip.hover
          title="WhatsApp"
          class="floating-menu-item floating-menu-item3"
        >
          <img src="@/assets/images/icons/button3.svg" class="" alt="image" />
        </b-button>

        <!-- Get a Quote Icon -->
        <b-button
          variant="info"
          class="floating-menu-item floating-menu-item2"
          v-b-tooltip.hover
          title="Get a Quote"
          ref="getQuoteRef"
          href="#getQuote"
          @click="getQuote"
        >
          <img src="@/assets/images/icons/button1.svg" class="" alt="image" />
        </b-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FloatingButton",

  data() {
    return {
      menuOpen: false,
      whatsappNumber: "923196048487",
    };
  },
  methods: {
    onMouseEnter() {
      this.menuOpen = true; // Open the menu when mouse enters button or menu
    },
    onMouseLeave() {
      // Close the menu only if the mouse has left both the button and the menu area
      this.checkMenuVisibility();
    },
    checkMenuVisibility() {
      // Check if the mouse is within the bounds of the menu or button
      const menuContainer = this.$refs.menuContainer;
      const contactButton = this.$refs.contactButton;
      const menuItems = this.$refs.menuItems;

      if (
        !menuContainer.contains(document.activeElement) &&
        !contactButton.contains(document.activeElement) &&
        !menuItems.contains(document.activeElement)
      ) {
        this.menuOpen = false; // Close the menu
      }
    },
    contactPage() {
      this.$router.push({ name: "ContactUs" });
    },
    getQuote() {
      this.$router.push({ path: "/", hash: "#getQuote" });
    },
  },
  computed: {
    ...mapGetters({ getCart: "appData/getCart" }),
    whatsappLink() {
      return `https://wa.me/${this.whatsappNumber}`;
    },
  },
};
</script>

<style scoped>
.floating-menu {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 1000;
}

/* Main floating button */
.floating-menu-item {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Hover effect for main button */
.floating-menu-item:hover {
  transform: scale(1.2);
  box-shadow: 0 4px 10px hsla(0, 0%, 0%, 0.9);
}

.floating-menu-item1:hover {
  border: 2px solid #091e3d !important;
}
.floating-menu-item2:hover {
  background-color: #00a3b5 !important;
  border: 2px solid #091e3d !important;
}
.floating-menu-item3:hover {
  background-color: #21a65d !important;
  border: 2px solid #091e3d !important;
}

/* Container for menu items */
.floating-menu-items {
  position: absolute;
  bottom: 80px;
  right: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Transition animations for sliding menu */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
